/* global tw */
import React from 'react'
import Recaptcha from 'react-recaptcha'
import styled from '@emotion/styled'
import useSSR from 'use-ssr'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Link from '../components/link'

const Container = styled('div')`
  ${tw('-mt-2')};
`

const Form = styled('form')`
  ${tw('w-full')};
`

const Select = styled('select')`
  ${tw('p-1 text-xs appearance-none border-gray-800 focus:outline-none rounded')};
`

const Textbox = styled('input')`
  ${tw('text-xs appearance-none bg-transparent border-0 border-gray-800 focus:outline-none')};
  border-bottom: 1px solid;
`

const Textarea = styled('textarea')`
  ${tw('block w-full rounded')};
  resize: none;
`

const Button = styled('button')`
  ${tw('p-2 text-md cursor-pointer')};
`

const Result = styled('span')`
  ${tw('block p-2 my-4 bg-blue-200 rounded shadow')};
`

class ContactPage extends React.Component {
  constructor(props) {
    super(props)
    this.submit = this.submit.bind(this)
    this.state = {
      status: '',
    }
  }

  isValidForm = (form) => {
    if (form.project.value?.trim().length === 0) {
      return false
    }

    if (form.subject.value?.trim().length === 0) {
      return false
    }

    if (form.description.value?.trim().length === 0) {
      return false
    }

    if (form.sender.value?.trim().length === 0) {
      return false
    }
  }

  submit = (e) => {
    e.preventDefault()
    const form = e.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()

    if (this.isValidForm(form)) {
      xhr.open(form.method, form.action)
      xhr.setRequestHeader('Accept', 'application/json')
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return
        if (xhr.status === 200) {
          form.reset()
          this.setState({ status: 'SUCCESS' })
        } else if (JSON.parse(xhr.response).error === 'reCAPTCHA failed') {
          this.setState({ status: 'RECAPTCHA' })
        } else {
          this.setState({ status: 'ERROR' })
        }
      }
      xhr.send(data)
    } else {
      this.setState({ status: 'ERROR' })
    }
  }

  render() {
    const { isBrowser } = useSSR()
    const { status } = this.state

    return (
      <Container>
        <h1>Looking to start an exciting new project?</h1>
        <p>
          <Link url="mailto:hi@marcsantos.com">Let&#39;s talk</Link> or complete and submit the form below.
        </p>
        <Form onSubmit={this.submit} action="https://formspree.io/f/xdowodqa" method="POST">
          <h3>Hi Marc!</h3>
          <p>
            I want you to{' '}
            <Select name="reason" aria-label="Your reason for contacting me">
              <option>make something awesome for me</option>
              <option>answer my question</option>
            </Select>
            .
          </p>
          <p>
            My project is called{' '}
            <Textbox
              type="text"
              name="project"
              placeholder="Kickass Project"
              aria-label="Your project's name"
              required
            />{' '}
            and it&#39;s going to revolutionize{' '}
            <Textbox type="text" name="subject" placeholder="Martial Arts" aria-label="Your target market" required />.
          </p>
          <p>
            I&#39;ve got a budget of $
            <Textbox type="number" name="budget" placeholder="1,000" aria-label="Your budget" required min="1000" />
            USD to make it happen.
          </p>
          <p>
            I heard you like nice, succinct descriptions of what makes my idea so wonderful, so here&#39;s a short
            description of what it is, and why I&#39;m so excited for us to work on it;
          </p>
          <Textarea
            rows="15"
            name="description"
            placeholder="This project would crack open a new platform to enable aspiring kicksters to be trained by master shifus around the world -at the comfort of their homes."
            aria-label="Provide me with a short description of what you are trying to accomplish"
            required
          />
          <p>
            Email me at{' '}
            <Textbox
              type="email"
              name="contact"
              placeholder="awesome.person@me.com"
              aria-label="Your email address"
              required
            />{' '}
            and let&#39;s change the world.
          </p>
          <p>Love lot&#39;s,</p>
          <p>
            <Textbox type="text" name="sender" placeholder="Awesome Person" aria-label="Your name" required />
          </p>
          {isBrowser && <Recaptcha sitekey="6LedRykTAAAAAFACsMKTFzZRmtcnnJtxASSp4S6B" />}
          <p>
            xoxo{' '}
            {status === 'SUCCESS' ? (
              <Result>Thanks! You&#39;re message was sent successfully.</Result>
            ) : (
              <Button type="submit" title="Submit">
                <FontAwesomeIcon icon="paper-plane" /> Submit
              </Button>
            )}
            {status === 'RECAPTCHA' && <Result>reCAPTCHA failed to validate. Please try again.</Result>}
            {status === 'ERROR' && (
              <Result>
                Oops! There was an error sending your message. Please send me an{' '}
                <Link url="mailto:hi@marcsantos.com">email</Link> instead, or try again later.
              </Result>
            )}
          </p>
        </Form>
      </Container>
    )
  }
}

export default ContactPage
